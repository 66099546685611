import React from 'react'
import './Loading.css' // Make sure the CSS file path is correct for your project structure

const Loading = () => (
  <div className="loading-container">
    <div className="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
)

export default Loading
