const formatTimeSince = (date) => {
  if (!(date instanceof Date)) {
    date = date.toDate();
  }
  const now = new Date();
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${interval} years sen`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months sen`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval}d sen`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval}h sen`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval}m sen`;
  }
  return `${Math.floor(seconds)} seconds sen`;
};

export default formatTimeSince;
