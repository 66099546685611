// AdminIndicator.jsx
import React, { useState } from 'react';
import './AdminIndicator.css'; // Skapa en CSS-fil för att styla adminindikatorn

const AdminIndicator = ({ onLogout }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleIndicatorClick = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <>
            <div className="admin-indicator" onClick={handleIndicatorClick}></div>
            {isModalOpen && (
                <div className="admin-modal">
                    <button onClick={() => {
                        localStorage.removeItem('adminMode');
                        onLogout();
                    }}>
                        Log Out
                    </button>
                </div>
            )}
        </>
    );
};

export default AdminIndicator;
