import { initializeApp } from 'firebase/app';
import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

const getAuthDomain = () => {
  const host = window.location.hostname;
  if (host === 'misiona.com') {
    return 'misiona.com';
  }
  return process.env.REACT_APP_AUTH_DOMAIN || 'makeamission.com'; // default to makeamission.com
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: getAuthDomain(),
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initiera Firebase
const app = initializeApp(firebaseConfig);

// Initiera Analytics och ställ in cookie_flags om du använder Firebase Analytics
if (process.env.REACT_APP_MEASUREMENT_ID) {
  const analytics = getAnalytics(app);
  setAnalyticsCollectionEnabled(analytics, true);
  console.log('Firebase Analytics initialized');
} else {
  console.warn('Firebase Analytics not initialized - measurement ID is missing');
}

export default app;
