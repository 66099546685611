// analytics.js
export const sendAnalyticsData = async (data) => {
    try {
        const response = await fetch('http://localhost:4242/collect', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error('Failed to send analytics data');
        }
    } catch (error) {
        console.error('Error sending analytics data:', error);
    }
};
