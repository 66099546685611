import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { getFirestore, doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Create context
const UserContext = createContext();

export const usePoints = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userPoints, setUserPoints] = useState(0);
  const [userBonuses, setUserBonuses] = useState(0);
  const [showBonusMessage, setShowBonusMessage] = useState(false);
  const [bankPoints, setBankPoints] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state added

  const auth = getAuth();
  const db = getFirestore();

const performTransaction = async pointsToTransfer => {
  if (!auth.currentUser) {
    console.log('User not logged in');
    return { success: false, missingPoints: 0 };
  }

  if (pointsToTransfer <= 0) {
    console.log('Invalid points to transfer');
    return { success: false, missingPoints: 0 };
  }

  const userRef = doc(db, 'users', auth.currentUser.uid);
  const bankRef = doc(db, 'bankValue', 'aC4cDQfCvwp8aYdOWlLC');

  try {
    const userDoc = await getDoc(userRef);
    const bankDoc = await getDoc(bankRef);

    if (!userDoc.exists() || !bankDoc.exists()) {
      console.log('User or bank document does not exist');
      return { success: false, missingPoints: 0 };
    }

    let userPoints = userDoc.data().points || 0;
    let bankPoints = bankDoc.data().totalPoints || 0;

    if (userPoints < pointsToTransfer) {
      const missingPoints = pointsToTransfer - userPoints;
      console.log('Insufficient user points for transaction');
      return { success: false, missingPoints: missingPoints };
    }

    userPoints -= pointsToTransfer; // Deduct points from user
    bankPoints += pointsToTransfer; // Add points to bank

    // Update user and bank documents with new points values
    await updateDoc(userRef, { points: userPoints });
    await updateDoc(bankRef, { totalPoints: bankPoints });

    setUserPoints(userPoints); // Update local state for user points
    setBankPoints(bankPoints); // Update local state for bank points

    console.log('Transaction successful');
    return { success: true, missingPoints: 0 };
  } catch (error) {
    console.error('Transaction failed: ', error);
    return { success: false, missingPoints: 0 };
  }
};




  const rewardUser = async (pointsToReward, selectedMission, motivering) => {
    const GiveOrGet = selectedMission.GiveOrGet;
    const missionCreatorId = selectedMission.createdBy; // ID of the mission creator
    const motivationUserId = motivering.userId; // ID of the user who submitted the motivation

    console.log(
      `Starting to reward user. Points: ${pointsToReward}, Mission Type: ${GiveOrGet}, Motivation User ID: ${motivationUserId}, Mission Creator ID: ${missionCreatorId}`
    );

    if (!auth.currentUser) {
      console.error('User not logged in');
      return;
    }

    // Adjusted logic for determining the target user ID based on the mission type
    let targetUserId;
    if (GiveOrGet === 'give') {
      targetUserId = missionCreatorId; // For GIVE missions, the mission creator gets rewarded
    } else if (GiveOrGet === 'get') {
      targetUserId = motivationUserId; // For GET missions, the user who submitted the motivation gets rewarded
    } else {
      console.error('Invalid mission type');
      return;
    }

    console.log(
      `RewardUser called for points: ${pointsToReward}, Target User ID: ${targetUserId}`
    );

    const bankRef = doc(db, 'bankValue', 'aC4cDQfCvwp8aYdOWlLC');
    const userRef = doc(db, 'users', targetUserId);

    try {
      // Performing the transaction
      await updateDoc(bankRef, { totalPoints: increment(-pointsToReward) });
      await updateDoc(userRef, { points: increment(pointsToReward) });

      // Directly update the local bank points state
      setBankPoints(prevBankPoints => prevBankPoints - pointsToReward);

      // If the currently logged-in user is the one receiving points, update local state
      if (auth.currentUser.uid === targetUserId) {
        // Fetching updated points for the user
        const updatedUserDoc = await getDoc(userRef);
        const updatedUserPoints = updatedUserDoc.data().points;

        // Updating the local state with the fetched value
        setUserPoints(updatedUserPoints);

        console.log(
          `Reward operation completed. Points: ${pointsToReward} awarded to User ID: ${targetUserId}`
        );
      }
    } catch (error) {
      console.error('Reward failed: ', error);
    }
  };

  const updateUserBonuses = async () => {
    if (auth.currentUser) {
      const userRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const currentBonuses = userDoc.data().bonuses || 0;
        const updatedBonuses = currentBonuses + 1; // Add one to the current bonus count
        await updateDoc(userRef, { bonuses: updatedBonuses });
        setUserBonuses(updatedBonuses);
        setShowBonusMessage(true);
        setTimeout(() => setShowBonusMessage(false), 2000);
      }
    }
  };

  const updateUserPoints = async (newPoints, updateImmediately = false) => {
    console.log(`Updating user points from ${userPoints} to ${newPoints}.`);

    if (updateImmediately) {
      setUserPoints(newPoints);
    }

    if (auth.currentUser) {
      const userRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userRef, { points: newPoints }).then(() => {
        if (!updateImmediately) {
          setUserPoints(newPoints);
        }
        console.log('User points updated in Firestore');
      });
    }
  };

  const fetchBankValue = useCallback(async () => {
    const bankRef = doc(db, 'bankValue', 'aC4cDQfCvwp8aYdOWlLC'); // Updated to use the specific bank document ID
    const bankDoc = await getDoc(bankRef);
    if (bankDoc.exists()) {
      setBankPoints(bankDoc.data().totalPoints);
    } else {
      console.log('No bank value found');
    }
  }, [db]);

  useEffect(() => {
    fetchBankValue();
  }, [fetchBankValue]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async currentUser => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserPoints(userDoc.data().points);
          setUserBonuses(userDoc.data().bonuses);
        }
      } else {
        setUserPoints(0);
      }
      setLoading(false); // Set loading to false after data is fetched
    });

    return () => unsubscribe();
  }, [auth, db]);

  return (
    <UserContext.Provider
      value={{
        userPoints,
        userBonuses,
        showBonusMessage,
        setShowBonusMessage,
        bankPoints,
        updateUserPoints,
        updateUserBonuses,
        performTransaction,
        rewardUser,
        setUserPoints,
        loading, // Provide loading state
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
