import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const defaultLanguage = 'sv';
  const [language, setLanguage] = useState(defaultLanguage);


  useEffect(() => {
    const pathLang = location.pathname.split('/')[1];
    if (['en', 'sv'].includes(pathLang)) {
      setLanguage(pathLang);
    } else {
      navigate(`/${defaultLanguage}${location.pathname}`);
    }
  }, [location, navigate]);

  useEffect(() => {
    if (language !== defaultLanguage && location.pathname.includes(defaultLanguage)) {
      navigate(location.pathname.replace(defaultLanguage, language));
    }
  }, [language, defaultLanguage, location, navigate]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

