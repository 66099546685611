import React, { useState, useEffect, useRef } from 'react';
import './CookieBanner.css';

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(false);
    const [showReadMore, setShowReadMore] = useState(false);
    const acceptButtonRef = useRef(null);

useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent) {
        setShowBanner(false);  // Visa inte bannern om samtycke redan finns
    } else {
        setShowBanner(true);  // Visa bannern om samtycke inte finns
    }
}, []);


const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
    if (window.gtag) {
        window.gtag('consent', 'update', {
            'ad_storage': 'granted',
            'analytics_storage': 'granted'
        });
    }
};


    const handleDecline = () => {
        setShowBanner(false);
    };

    return (
        showBanner && (
            <div className="cookie-banner">
                <div className="cookie-content">
                    <div className='cookie-do'>


                    <div className="cookie-text">
                        We use cookies to improve your experience. By using our site, you agree to our use of cookies. 

                    </div>
                        <div className="read-more-button" onClick={() => setShowReadMore(true)}>Read More</div>

                    </div>

                    <div className="cookie-buttons">
                        <button className="decline-button-cookie" onClick={handleDecline}>Accept</button>
                        <button className="accept-button-cookie" ref={acceptButtonRef} onClick={handleAccept}>Accept ALL</button>
                    </div>
                </div>
                {showReadMore && (
                    <div className="read-more-text">
                        <p>Cookies are essential for the proper functioning of this site. They help us provide you with a better user experience by remembering your preferences and repeat visits. By accepting our use of cookies, you consent to the use of cookies in accordance with our cookie policy.</p>
                    </div>
                )}
            </div>
        )
    );
};

export default CookieBanner;
