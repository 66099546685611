// MovelandComponent.jsx
import React, { useEffect, useRef } from 'react';
import './Game.css'; // Ensure this file includes the necessary styles

const MovelandComponent = ({ baseConfig }) => {
    const movelandElementsRef = useRef([]);

const handleScroll = () => {
    const movelands = movelandElementsRef.current;
    movelands.forEach((moveland) => {
        if (moveland) {
            const initialTop = parseFloat(moveland.getAttribute('data-initial-top'));
            const scrollFactor = parseFloat(moveland.getAttribute('data-scroll-factor'));
            const heightIncreaseFactor = parseFloat(moveland.getAttribute('data-height-increase-factor'));

            // Ensure values are valid numbers
            const validInitialTop = isNaN(initialTop) ? 0 : initialTop;
            const validScrollFactor = isNaN(scrollFactor) ? 0 : scrollFactor;
            const validHeightIncreaseFactor = isNaN(heightIncreaseFactor) ? 0 : heightIncreaseFactor;

            const newTop = validInitialTop - window.scrollY * validScrollFactor;
            const newHeight = parseFloat(moveland.getAttribute('data-initial-height')) - window.scrollY * validHeightIncreaseFactor;

            moveland.style.top = `${newTop}px`;
            moveland.style.height = `${newHeight}px`;
        }
    });
};



    useEffect(() => {
        const movelands = movelandElementsRef.current;
        movelands.forEach((moveland) => {
            moveland.setAttribute('data-initial-top', parseFloat(moveland.style.top));
            moveland.setAttribute('data-initial-height', parseFloat(moveland.style.height));
        });

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const movelandElements = Array.from({ length: 10 }).map((_, index) => {
        const initialTop = baseConfig.initialTop + baseConfig.topIncrement * index;
        const scrollFactor = baseConfig.scrollFactor + baseConfig.scrollFactorIncrement * index;
        const heightIncreaseFactor = baseConfig.heightIncreaseFactor + baseConfig.heightIncreaseFactorIncrement * index;
        const initialHeight = Math.random() * 200 + 100;

        // Ensure values are valid numbers before setting attributes
        const validInitialTop = isNaN(initialTop) ? 0 : initialTop;
        const validScrollFactor = isNaN(scrollFactor) ? 0 : scrollFactor;
        const validHeightIncreaseFactor = isNaN(heightIncreaseFactor) ? 0 : heightIncreaseFactor;
        const validInitialHeight = isNaN(initialHeight) ? 100 : initialHeight;

        return (
            <div
                key={index}
                className='moveland'
                ref={(el) => (movelandElementsRef.current[index] = el)}
                style={{ top: `${validInitialTop}px`, height: `${validInitialHeight}px` }}
                data-initial-top={validInitialTop}
                data-scroll-factor={validScrollFactor}
                data-height-increase-factor={validHeightIncreaseFactor}
                data-initial-height={validInitialHeight}
            ></div>
        );
    });

    return <>{movelandElements}</>;
};

export default MovelandComponent;
