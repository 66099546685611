// FirebaseReadCounterContext.js
import React, { createContext, useState, useContext } from 'react'

const FirebaseReadCounterContext = createContext()

export const useFirebaseReadCounter = () =>
  useContext(FirebaseReadCounterContext)

export const FirebaseReadCounterProvider = ({ children }) => {
  const [readCounter, setReadCounter] = useState(0)

  // Function to increment the read counter
  const incrementReadCounter = (increment = 1) => {
    setReadCounter(readCounter => readCounter + increment)
  }

  return (
    <FirebaseReadCounterContext.Provider
      value={{ readCounter, incrementReadCounter }}
    >
      {children}
    </FirebaseReadCounterContext.Provider>
  )
}
