import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './ImageOverlay.css';

const ImageOverlay = ({ imageUrl, missionId, language, missionType }) => {
    const navigate = useNavigate();
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(true);

    const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);
    }, [prevScrollPos]);

    useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
    <img
        src={imageUrl}
        className={`image-game-center ${missionType} ${visible ? '' : 'down'}`}
        alt="Mission Visual"
        onClick={() => navigate(`/${language}/play/${missionId}`)}
    />
    );
};

export default ImageOverlay;
