// useAuth.js
import { useState, useEffect, useMemo } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';

function useAuth() {
  const [user, setUser] = useState(null);
  const defaultProfileImageUrl = 'gs://misiona-d1330.appspot.com/defaultprofile.png'; // Replace with your actual URL

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const unsubscribe = onAuthStateChanged(auth, async currentUser => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
const ratings = userData.motivationRating || [];
const totalRatings = ratings.length;
const averageRating = ratings.reduce((acc, cur) => acc + cur, 0) / totalRatings || 0;

          setUser({
            ...currentUser,
            email: currentUser.email,
            username: userData.username,
            profileImageUrl: userData.profileImageUrl || defaultProfileImageUrl,
            bio: userData.bio || '',
            bonuses: userData.bonuses || 0,
            points: userData.points || 0,
            favorites: userData.favorites || [],
            totalMissions: userData.totalMissions || [],
            averageMissionRating: averageRating,
            totalMissionRatings: totalRatings,
          });
        } else {
          setUser({
            ...currentUser,
            email: currentUser.email,
            username: '',
            profileImageUrl: defaultProfileImageUrl,
            bio: '',
            bonuses: 0,
            points: 0,
            favorites: [],
            totalMissions: [],
            averageMissionRating: 0,
            totalMissionRatings: 0,
          });
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const updatePoints = async (newPoints, callback) => {
    if (user) {
      const db = getFirestore();
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, { points: newPoints });
      setUser(prevUser => ({ ...prevUser, points: newPoints }));
      if (callback) callback(newPoints); // Call the callback with new points
    }
  };

  const memoizedUser = useMemo(() => user, [user]);

  return { user: memoizedUser, updatePoints };
}

export default useAuth;
