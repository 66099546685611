import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import './NavigationBar.css';
import { usePoints } from './hooks/UserContext';
import { useLanguage } from './hooks/LanguageContext';
import LoginRegister from './Pages/LoginRegister'; // Import the LoginRegister component

Modal.setAppElement('#root');

// Lazy load the PointPage component
const LazyPointPage = lazy(() => import('./Pages/PointPage'));

const NavigationBar = ({ user, paymentSuccess, updatedPoints }) => {
  const { userPoints, bankPoints, setUserPoints, loading } = usePoints();
  const [previousPoints, setPreviousPoints] = useState(null);
  const [isGamePage, setIsGamePage] = useState(false);
  const [isPointsModalOpen, setIsPointsModalOpen] = useState(false);
  const [isLoginRegisterModalOpen, setIsLoginRegisterModalOpen] = useState(false); // State for LoginRegister modal
  const [tempUsername, setTempUsername] = useState(''); // Lägg till denna rad
  const [isRegisteringVisible, setIsRegisteringVisible] = useState(false); // Lägg till denna rad
  const navigate = useNavigate();
  const location = useLocation();
  const { showBonusMessage } = usePoints();
  const { language, setLanguage } = useLanguage();
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setTempUsername(storedUsername);
    }
  }, []);

  useEffect(() => {
    console.log('NavigationBar mounted');
    const navigationElement = document.querySelector('.navigation');
    if (navigationElement) {
      navigationElement.classList.add('animate-in');
    }
    return () => {
      console.log('NavigationBar unmounted');
    };
  }, []);

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const formatPoints = (points) => {
    if (points < 1000) return points.toString();
    if (points < 1000000) return points.toString();
    return (points / 1000000).toFixed(1) + 'M';
  };

useEffect(() => {
  const currentPath = location.pathname;
  const isGame = currentPath === `/${language}/` || currentPath.startsWith(`/${language}/play`);
  if (isGame !== isGamePage) {
    setIsGamePage(isGame);
    console.log('useEffect for location/language: Current path:', currentPath, 'Language:', language, 'isGamePage:', isGame);
  }
}, [location.pathname, language, isGamePage]);


  useEffect(() => {
    console.log('useEffect for userPoints:', userPoints, 'previousPoints:', previousPoints);
    if (previousPoints === null && userPoints !== null) {
      setPreviousPoints(userPoints);
    }
    // eslint-disable-next-line 
  }, [userPoints]);

  useEffect(() => {
    console.log('useEffect for previousPoints change:', 'userPoints:', userPoints, 'previousPoints:', previousPoints);
    if (previousPoints !== null && userPoints !== previousPoints) {
      setTimeout(() => setPreviousPoints(userPoints), 5000);
    }
  }, [userPoints, previousPoints]);

  useEffect(() => {
    console.log('useEffect for bankPoints:', bankPoints);
  }, [bankPoints]);

const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);
}, [prevScrollPos]);


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (paymentSuccess) {
      console.log('Payment success, updating points in NavigationBar:', userPoints);
      setUserPoints(updatedPoints);  // Uppdatera poängen direkt med updatedPoints
    }
  }, [paymentSuccess, updatedPoints, setUserPoints, userPoints]);

  useEffect(() => {
    if (updatedPoints !== null && updatedPoints !== undefined) {
      setUserPoints(prevPoints => prevPoints + updatedPoints);
      console.log('Updated points in NavigationBar:', updatedPoints);
    }
  }, [updatedPoints, setUserPoints]);

  const pointsDifference = previousPoints !== null && previousPoints !== undefined ? userPoints - previousPoints : 0;
  const pointsDiffStyle = pointsDifference >= 0 ? { color: 'green' } : { color: 'red' };

  const goToProfile = () => {
    navigate(`/${language}/profil`);
  };

  const handlePointsClick = () => {
    setIsPointsModalOpen(true);
  };

  const handleClosePointsModal = () => {
    setIsPointsModalOpen(false);
  };

  const handleLoginRegisterClick = () => {
    setIsLoginRegisterModalOpen(prevState => !prevState);
    if (!isLoginRegisterModalOpen) {
      setIsRegisteringVisible(false); // Återställ när modalen öppnas
    }
  };

  const handleCloseLoginRegisterModal = () => {
    setIsLoginRegisterModalOpen(false);
  };

  useEffect(() => {
    console.log(`useEffect for userPoints update: Current user points: ${userPoints}`);
  }, [userPoints]);

  useEffect(() => {
    console.log(`useEffect for bankPoints update: Current bank points: ${bankPoints}`);
  }, [bankPoints]);

  if (loading) {
    return null; // Or render a loading spinner
  }

  return (
    <div className='navigationbar'>
      <div className={`navigation ${visible ? 'visible' : 'hidden'}`}>
        <div className='squarediv'>
<div className='nav-bar-back-container'>
  <div
    className={`nav-bar-back ${isGamePage ? 'rotate' : ''}`}
    onClick={() => navigate(location.pathname === `/${language}/` ? `/${language}/create` : `/${language}/`)}
  >
    +
  </div>

</div>

        </div>
        <select className="select-lang" value={language} onChange={handleLanguageChange}>
          <option value="en">English</option>
          <option value="sv">Svenska</option>
        </select>
        {isGamePage && <div className="add-text" 
            onClick={() => navigate(location.pathname === `/${language}/` ? `/${language}/create` : `/${language}/`)}

>+</div>}
{isGamePage && location.pathname === `/${language}/` && (
  <div 
  onClick={() => navigate(location.pathname === `/${language}/` ? `/${language}/create` : `/${language}/`)}
>
    <p className='skapamission'
>SKAPA UPPDRAG</p>
  </div>
)}


        {user ? (
          <>
            <div className="bank-points">BP: {bankPoints}</div>
            <div className="point-and-profilepic">
              {showBonusMessage && <p>+ 1</p>}
              <div className="points-difference" style={pointsDiffStyle}>
                {pointsDifference !== 0 && previousPoints !== null ? `${pointsDifference > 0 ? '+' : ''}${pointsDifference}` : ''}
              </div>
              <div className="navigation-points" onClick={handlePointsClick} style={{ cursor: 'pointer' }}>
                {formatPoints(userPoints)} ✨ SEK
              </div>
              <div
                className="navigation-profile"
                style={{ backgroundImage: `url(${user?.profileImageUrl})` }}
                onClick={goToProfile}
              ></div>
            </div>
          </>
        ) : (
<div className="point-and-profilepic">
  <div className="points-difference" style={pointsDiffStyle}>
    {pointsDifference !== 0 && previousPoints !== null ? `${pointsDifference > 0 ? '+' : ''}${pointsDifference}` : ''}
  </div>
  <div 
    className="navigation-points" 
    onClick={user ? handlePointsClick : handleLoginRegisterClick} 
    style={{ cursor: 'pointer' }}
  >
    {formatPoints(userPoints || 0)} ✨ Svenska kr
  </div>
  <div
    className="navigation-profile"
    style={{ backgroundImage: `url(${user?.profileImageUrl || '/path/to/default/profileImage.png'})` }}
    onClick={user ? goToProfile : handleLoginRegisterClick}
  ></div>
</div>

        )}
      </div>
      <Modal
        isOpen={isPointsModalOpen}
        onRequestClose={handleClosePointsModal}
        contentLabel="Buy Points Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <Suspense fallback={<div>Loading...</div>}>
          {isPointsModalOpen && <LazyPointPage isOpen={isPointsModalOpen} onCloseModal={handleClosePointsModal} />}
        </Suspense>
      </Modal>
      <Modal
        isOpen={isLoginRegisterModalOpen}
        onRequestClose={handleCloseLoginRegisterModal}
        contentLabel="Login Register Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <LoginRegister 
          onClose={handleCloseLoginRegisterModal} 
          onUsernameChange={setTempUsername} 
          setIsRegisteringVisible={setIsRegisteringVisible} 
        />
      </Modal>
    </div>
  );
};

export default NavigationBar;
