const translations = {
    en: {
        missionTitle: "PICK A MISSION",
        give: "GIVE",
        get: "GET"
    },
    sv: {
        missionTitle: "VÄLJ UPPDRAG",
        give: "GE",
        get: "FÅ"
    }
};

export default translations;
